<template>
    <div class="card">
        <div class="card-header">{{ $t('crud.administration.title') }}</div>
        <div class="card-body">
            <table class="table">
                <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Bestellingen klaar voor afronding</th>
                        <th>Bekijken</th>
                        <th>Afronden</th>
                        <th>Afgeronde bestellingen</th>
                        <th>Volume</th>
                        <th>FBASC.hia</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in rows">
                        <td>{{ $moment(row.start).format('MM/YYYY') }}</td>
                        <td>{{ row.orders_to_be_completed_count }}</td>
                        <td><router-link :to="linkViewOrders(row)" class="btn btn-sm btn-outline-secondary">Bekijken</router-link></td>
                        <td><button @click="completeOrders(row, index)" v-if="row.orders_to_be_completed_count" class="btn btn-sm btn-outline-secondary">
                            <loading v-if="loading_rows[index]" /> Afronden
                        </button></td>
                        <td>{{ row.orders_completed_count }}</td>
                        <td>{{ row.orders_completed_volume }} m²</td>
                        <td><button @click="exportAdministration(row)" class="btn btn-sm btn-primary">
                            <i class="fa fa-file-text-o"></i> Export
                        </button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.loadAdministration();
        },
        data() {
            return {
                rows: [],
                loading_rows: []
            }
        },
        methods: {
            linkViewOrders(row) {
                return {
                    name: 'orders.index',
                    query: {
                        filters: JSON.stringify({
                            shipping_date_from: this.$moment(row.start).format('YYYY-MM-DD'),
                            shipping_date_to: this.$moment(row.end).format('YYYY-MM-DD'),
                            status: [3]
                        })
                    }
                };
            },
            loadAdministration() {
                this.$http.get('administration').then((response) => {
                    this.rows = response.data;
                });
            },
            completeOrders(row, index) {
                this.$set(this.loading_rows, index, true);
                this.$http.post('administration/complete-orders', {
                    start: row.start,
                    end: row.end
                }).then(() => {
                    this.loadAdministration();
                    this.$toastr.s(this.$t('notifications.completed'));
                    this.$set(this.loading_rows, index, false);
                }).catch(() => {
                    this.$set(this.loading_rows, index, false);
                });
            },
            exportAdministration(row) {
                this.$http.post('administration/fbasc-export', {
                    start: row.start,
                    end: row.end
                }, {
                    responseType: 'blob'
                }).then((response) => {
                    let url = window.URL.createObjectURL(new Blob([response.data]));
                    let link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'FBASC.hia');
                    document.body.appendChild(link);
                    link.click();
                });
            }
        }
    }
</script>
